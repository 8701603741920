import jQuery from 'jquery/dist/jquery';
import * as bootstrap from 'bootstrap'
import Swal from 'sweetalert2';
import Utils from '../../../../../utils'
import ErpShipmentEntity from "../../../shipment/entity";

export default class ErpOrderWMS {
    private parent: any;
    private entity = "";
    private toastr: any;

    constructor(parent: any) {
        this.parent = parent
        this.toastr = parent.toastr
        this.bindListeners();
    }

    getEntityData(elem: any) {
        return {}
    }

    bindListeners() {

    }

    async update(data: any) {
        console.log("data", data)
        const elem = document.querySelector("#erp_order_wms") as HTMLElement;
        let html = '';
        data.wms.forEach((wms: any) => {
            html += `<div class="row mb-4">
                <div class="col-12">
                  <div class="card">
                    <div class="card-header border-bottom">
                        <h5 class="card-title mb-3">${new Date(Date.parse(wms.created_at)).toLocaleString()} - ${Utils.translate(`erp.order.wms.status.${wms.status}`)}, ${wms.user.name}</h5>
                      </div>
                    <div class="card-body">
                      <table class="datatables-erp-order-shipment table">
                        <thead class="border-top">
                        <tr>
                          <th>Item</th>
                          <th>Serial</th>
                          <th>Picked</th>
                          <th>Picked at</th>
                        </tr>
                        </thead>
                        <tbody>`;
            wms.items.forEach((item: any) => {
                html += `<tr>
                    <td>${data.lineItems.filter(i => i.id === item.order_line_item_uuid)[0].label}</td>
                    <td>${item.serial}</td>
                    <td>${item.picked ? '<i class="ti ti-checkbox ti-sm"></i>' : '<i class="ti ti-square-off ti-sm"></i>'}</td>
                    <td>${new Date(Date.parse(item.picked_at)).toLocaleString()}</td>
                </tr>`
            })
            html += ` </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>`
        });
        elem.innerHTML = html;
    }
}